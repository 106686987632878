.screen-center {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
}
.text-center {
    text-align: center;
}
.btn-submit {
    width: 100%;
}